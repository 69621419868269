import React from 'react';
import cn from 'classnames';
import { LayoutProvider, TextAnimation } from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';

interface IProps {
  body: string;
}

const FigmaHeroText = ({ body }: IProps) => {
  return (
    <LayoutProvider grid paddingX paddingY>
      <div className={styles.textWrapper}>
        <TextAnimation
          text={body}
          className={cn('h1', styles.text)}
          animation="typed"
        />
      </div>
    </LayoutProvider>
  );
};

export default FigmaHeroText;
