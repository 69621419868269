export const parseImageFromFigma = (supabaseProject: string, image: string) => {
  if (!supabaseProject || !image) {
    return null;
  }

  const PROJECT_REF = process.env.GATSBY_SUPABASE_PROJECT_REF || '';

  const imageSplit = image.split(':');

  const fileType = imageSplit[1];
  const fileName = imageSplit[2];

  // todo: validation, etc.
  const imageUrl = `https://${PROJECT_REF}.supabase.co/storage/v1/object/public/images/${supabaseProject}/${fileName}.${fileType}`;

  return {
    image: imageUrl,
    button: {
      text: 'Download',
      url: '/'
    }
  };
};

export const parseImagesFromFigma = (
  supabaseProject: string,
  images: string[]
) => {
  if (!supabaseProject || !images?.[0]) {
    return [];
  }

  const gridItems = images.map((image) => {
    return parseImageFromFigma(supabaseProject, image);
  });

  return gridItems;
};

export const parseButtonDataFromFigma = (labels: (string | undefined)[]) => {
  const buttons: { text: string; url: string }[] = [];

  labels.forEach((label) => {
    if (!label) {
      return;
    }

    let text = label;
    let url = '/';

    if (label?.includes('>')) {
      const labelSplit = label.split('>');

      text = labelSplit[0];
      url = labelSplit[1];
    }

    buttons.push({
      text,
      url
    });
  });

  return buttons;
};
