import React from 'react';
import cn from 'classnames';
import {
  IntersectionAnimation,
  LayoutProvider
} from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';

interface IProps {
  data: {
    heading?: string;
    bodyLeft?: string;
    bodyRight?: string;
  };
  animationDelay?: number;
}

const TypeRow = ({ data, animationDelay }: IProps) => {
  const { heading, bodyLeft, bodyRight } = data || {};

  return (
    <article className={styles.container}>
      <LayoutProvider grid>
        {heading && (
          <IntersectionAnimation className={styles.heading}>
            <h2 className="b1">{heading}</h2>
          </IntersectionAnimation>
        )}

        <div className={styles.body}>
          {bodyLeft && (
            <IntersectionAnimation
              delay={animationDelay ? animationDelay * 1 : 0}
            >
              <p className="b1">{bodyLeft}</p>
            </IntersectionAnimation>
          )}
          {bodyRight && (
            <IntersectionAnimation
              delay={animationDelay ? animationDelay * 2 : 0}
            >
              <p className="b1">{bodyRight}</p>
            </IntersectionAnimation>
          )}
        </div>
      </LayoutProvider>
    </article>
  );
};

export default TypeRow;
