// extracted by mini-css-extract-plugin
export var card = "styles-module--card--6e028";
export var cardBody = "styles-module--cardBody--a37ad";
export var cardHeader = "styles-module--cardHeader--780ca";
export var dark = "styles-module--dark--a08c4";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var light = "styles-module--light--809a8";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var split = "styles-module--split--cc1c7";
export var tablet = "769px";
export var text = "styles-module--text--1f8da";
export var textButton = "styles-module--textButton--f77b4";