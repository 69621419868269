import React from 'react';
import {
  LayoutProvider,
  SliceHeader,
  TypeEditor,
  ITypeEditorDefaultSettings
} from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';
import { useMVT } from '@lam-agency/toolkit/stores';

interface IProps {
  heading: string;
  exampleDisplay: string;
  exampleHeading: string;
  'exampleBody/1': string;
  'exampleBody/2': string;
  'exampleBody/3': string;
  'exampleBody/4': string;
}

const FigmaTypography = ({
  heading,
  exampleDisplay,
  exampleHeading,
  ...exampleBodies
}: IProps) => {
  const {
    variables: { downloadLinks }
  } = useMVT();

  const bodyDefault = {
    text: exampleBodies['exampleBody/1'],
    letterSpacing: 0,
    lineHeight: 122,
    alignment: 'left'
  } as ITypeEditorDefaultSettings;

  const headingDefault = {
    text: exampleHeading,
    letterSpacing: -0.03,
    lineHeight: 110,
    alignment: 'left'
  } as ITypeEditorDefaultSettings;

  const displayDefault = {
    text: exampleDisplay,
    // todo: check proper letterSpacing value in em
    letterSpacing: 0,
    lineHeight: 100,
    alignment: 'left'
  } as ITypeEditorDefaultSettings;

  const fontKitDownloadLink = downloadLinks.find(
    ({ title }) => title === 'Font Kit'
  );

  return (
    <LayoutProvider paddingX paddingY>
      <LayoutProvider grid>
        <SliceHeader
          className={styles.header}
          data={{
            heading,
            buttons: [
              {
                text: `Download ${fontKitDownloadLink?.title}`,
                url: fontKitDownloadLink?.url
              }
            ]
          }}
        />
      </LayoutProvider>

      <TypeEditor
        typestyle="Display"
        placeholder={exampleDisplay}
        defaultSettings={displayDefault}
      />

      <TypeEditor
        typestyle="Heading"
        placeholder={exampleHeading}
        defaultSettings={headingDefault}
      />

      <TypeEditor
        typestyle="Body"
        placeholder={exampleBodies['exampleBody/1']}
        defaultSettings={bodyDefault}
        noBorder
      />
    </LayoutProvider>
  );
};

export default FigmaTypography;
