import React from 'react';
import cn from 'classnames';
import { TSVG, Button, type IButton } from '@lam-agency/toolkit/components';
import {
  IntersectionAnimation,
  LayoutProvider
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';

interface ISliceHeaderButton extends IButton {
  text: string;
  url: string;
  icon?: TSVG;
}

export interface ISliceHeader {
  heading?: string;
  buttons?: ISliceHeaderButton[];
  alignButtons?: 'left' | 'right';
}

interface IProps {
  data: ISliceHeader;
  className?: string;
}

const SliceHeader = ({ data, className }: IProps) => {
  const { heading, buttons, alignButtons = 'right' } = data || {};
  const showButtons = buttons?.some((button) => button?.text && button?.url);

  let splitHeading: string | string[] | undefined = heading;

  if (heading && heading?.indexOf(`\n`) > -1) {
    splitHeading = heading?.split('\n');
  }

  const delayOffset = splitHeading ? 1 : 0;

  return (
    <LayoutProvider className={className} grid>
      <div className={styles.partition} aria-hidden />
      <header
        className={cn(styles.container, {
          [styles.alignLeft]: alignButtons === 'left',
          [styles.alignRight]: alignButtons === 'right'
        })}
      >
        {splitHeading && (
          <IntersectionAnimation>
            <h2 className="h2">{splitHeading}</h2>
          </IntersectionAnimation>
        )}

        {showButtons && (
          <div className={styles.buttons}>
            {buttons?.map((button, index) => {
              const { text, iconRight, url } = button || {};
              if (!text || !url) {
                return null;
              }

              const delay = (index + delayOffset) * 150;

              return (
                <IntersectionAnimation
                  key={`slice-header-button-${text}-${index}`}
                  delay={delay}
                >
                  <Button
                    to={button?.url}
                    iconRight={iconRight || 'arrowRight'}
                    fluid
                  >
                    {text}
                  </Button>
                </IntersectionAnimation>
              );
            })}
          </div>
        )}
      </header>
    </LayoutProvider>
  );
};

export default SliceHeader;
