import React from 'react';
import {
  ITokenGroup,
  componentTokens,
  globalTokens,
  sliceTokens
} from '@lam-agency/toolkit/custom/styleTokens';
import { stringToKebabCase } from '@lam-agency/toolkit/utils';
import { Helmet } from 'react-helmet';

export const StyleTokens = () => {
  const tokenGroups = [...componentTokens, ...sliceTokens, ...globalTokens]; // add more as needed

  return (
    <>
      {tokenGroups.map((tokenGroup, i) => (
        <TokenGroup key={i} {...tokenGroup} />
      ))}
    </>
  );
};

const TokenGroup = ({ name, tokens }: ITokenGroup) => {
  let css = `:root{`;

  Object.entries(tokens).forEach(([tokenName, tokenDetails]) => {
    css += `--${name}-${stringToKebabCase(tokenName)}: ${tokenDetails}; `;
  });

  css += `}`;

  return (
    <Helmet>
      <style>{css}</style>
    </Helmet>
  );
};
