import React from 'react';
import {
  GridVisualiser,
  IntersectionAnimation,
  LayoutProvider,
  SliceHeader,
  TypeRow
} from '@lam-agency/toolkit/components';
import { parseButtonDataFromFigma } from '@lam-agency/toolkit/utils/figma';

import * as styles from './styles.module.scss';

interface IProps {
  data: {
    fields: {
      heading?: string;
      buttonText?: string;
      subheading?: string;
      body1?: string;
      body2?: string;
    };
  };
}

const delay = 100;

const FigmaGrid = ({ data: { fields } }: IProps) => {
  const { heading, buttonText, subheading, body1, body2 } = fields || {};

  const buttons = parseButtonDataFromFigma([buttonText]);

  return (
    <LayoutProvider paddingX paddingY>
      <LayoutProvider grid>
        <SliceHeader className={styles.header} data={{ heading, buttons }} />
      </LayoutProvider>

      <TypeRow
        data={{ heading: subheading, bodyLeft: body1, bodyRight: body2 }}
        animationDelay={delay}
      />

      <IntersectionAnimation delay={delay * 4}>
        <GridVisualiser />
      </IntersectionAnimation>
    </LayoutProvider>
  );
};

export default FigmaGrid;
