import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import {
  IntersectionAnimation,
  LayoutProvider,
  SegmentedControl,
  SliceHeader
} from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';

interface IProps {
  data: {
    fields: {
      heading: string;
    };
  };
}

const FigmaCharacterInspector = ({
  data: {
    fields: { heading }
  }
}: IProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [letterCase, setLetterCase] = useState('uppercase');
  const [selectedCharacter, setSelectedCharacter] = useState('a');

  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789!#$%&+'.split('');

  const controlTabs = [
    {
      key: 'Uppercase',
      value: 'uppercase'
    },
    {
      key: 'Lowercase',
      value: 'lowercase'
    }
  ];

  useEffect(() => {
    setLetterCase(controlTabs[selectedTabIndex].value);
  }, [selectedTabIndex]);

  // useEffect(() => {
  //   console.log(letterCase, 'lettercase');
  // }, [letterCase]);

  return (
    <div className={styles.container}>
      <LayoutProvider grid paddingX>
        <SliceHeader className={styles.header} data={{ heading: heading }} />

        <div className={styles.controlWrapper}>
          <SegmentedControl
            className={styles.control}
            tabs={controlTabs}
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
          />
        </div>

        <IntersectionAnimation
          className={cn('d1', styles.preview, {
            [styles.lowercase]: letterCase === 'lowercase'
          })}
        >
          {selectedCharacter}
        </IntersectionAnimation>

        <div className={styles.characters}>
          {characters.map((character, index) => {
            const isSelected = character === selectedCharacter;

            return (
              <IntersectionAnimation
                key={`character-inspector-char-${index}`}
                delay={index * 50}
              >
                <p
                  className={cn('h1', styles.character, {
                    [styles.lowercase]: letterCase === 'lowercase',
                    [styles.selected]: isSelected
                  })}
                  onClick={() => setSelectedCharacter(character)}
                >
                  {character}
                </p>
              </IntersectionAnimation>
            );
          })}
        </div>
      </LayoutProvider>
    </div>
  );
};

export default FigmaCharacterInspector;
