import React from 'react';
import * as Slices from '@lam-agency/toolkit/slices';
import * as CustomSlices from '@lam-agency/toolkit/custom/slices';
import { ISliceData } from 'lam-toolkit-figma-plugin';

import * as styles from './styles.module.scss';

interface IProps {
  sliceData: ISliceData;
  anchorIds: string[];
}

interface IFieldDictionary {
  [key: string]: any;
}

// todo - add types for each figma slice in the plugin, and import them for the slice interfaces here

const FigmaSlice = ({ sliceData, anchorIds }: IProps) => {
  if (!sliceData) {
    return null;
  }

  const parsedName = sliceData?.name?.split(':')?.[1];

  let Slice = CustomSlices?.[`Figma${parsedName}` as keyof typeof CustomSlices];

  if (!Slice) {
    Slice = Slices?.[`Figma${parsedName}` as keyof typeof Slices];
  }

  if (!Slice) {
    console.error(`No slice found for ${parsedName}`);
    return null;
  }

  const props: any = {};

  sliceData.fields.forEach((field) => {
    props[field.name] = field.value;
  });

  const fields: IFieldDictionary = {};

  sliceData.fields.forEach((field) => {
    const value = (field as any).value;
    fields[field.name] = value;
  });

  const anchorLinks = anchorIds
    ?.map((id: string) => {
      if (id.includes('Hero')) return null; // Use return null; to skip iteration

      return {
        title: id.split(':')?.[1]?.replace(/([a-z])([A-Z])/g, '$1 $2'),
        id: id
      };
    })
    .filter((link) => link !== null); // Filter out null values

  return (
    <section id={`${sliceData.name}`} className={styles.container}>
      <Slice
        {...props}
        data={{
          fields,
          anchorLinks: parsedName === 'Hero' ? anchorLinks : []
        }}
      />
    </section>
  );
};

export default FigmaSlice;
