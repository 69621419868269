import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import {
  NoJs,
  Theme,
  Header,
  Footer,
  Notification,
  ScrollToTop,
  LoadingScreen,
  StyleTokens
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import 'modern-normalize/modern-normalize.css';
import '@lam-agency/toolkit/styles/global.css';
import { useSyncMVT } from '../hooks';
import { useMVT } from '../stores';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const Layout = ({ children, className }: IProps) => {
  useSyncMVT();
  const [haveFontsLoaded, setHaveFontsLoaded] = useState(false);
  const { hasMVTDataLoaded } = useMVT();

  // I'm not 100% sure if this is actually working, will have to see if pop-in is still an issue
  useEffect(() => {
    document.fonts.ready
      .then(() => {
        setHaveFontsLoaded(true);
      })
      .catch((error) => {
        console.error('An error occurred while loading fonts:', error);
      });
  }, []);

  const hasLoaded = haveFontsLoaded && hasMVTDataLoaded;

  return (
    <>
      <NoJs />
      <Theme />
      <StyleTokens />

      {hasLoaded && (
        <>
          <Header />

          <div className={cn(styles.container, className)}>{children}</div>

          <Footer />
          <ScrollToTop />
          <Notification />
        </>
      )}

      {!hasLoaded && <LoadingScreen />}
    </>
  );
};

export default Layout;
