import React, { useEffect, useMemo, useRef } from 'react';
import { ShaderPass } from 'three-stdlib';
import { extend, useFrame } from '@react-three/fiber';
import { useTexture } from '@react-three/drei';
import Ripple from './ripple.ts';

extend({ ShaderPass });

// --------------------------------------------------------
const vertexShader = `
  varying vec2 vUv;
  uniform float uTime;
  uniform vec2 uMouse;

  void main() {
    vUv = uv;
    vec3 pos = position;

    float dist = distance(uMouse, vUv);

    float waveDisplacement = sin(dist * 10.0 - uTime * 2.5) * 0.1;

    pos.y += waveDisplacement;

    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
  }
`;

const fragmentShader = `
  uniform sampler2D tDiffuse;
  uniform sampler2D uDisplacement;
  
  varying vec2 vUv;

  float PI = 3.141592653589;

  void main() {
    vec2 uv = vUv;

    vec4 disp = texture2D(uDisplacement, uv);
    float theta = disp.r * 2.0 * PI;
    vec2 dir = vec2(sin(theta), cos(theta));
    uv += dir * disp.r * 0.05;

    vec4 color = texture2D(tDiffuse, uv);

    gl_FragColor = color;
  }
`;

const RippleShader = ({ dimensions, pos }) => {
  const rippleTexture = useTexture(`/webgl/brush.png`);
  const effect = useMemo(
    () => new Ripple(rippleTexture, dimensions),
    [rippleTexture]
  );

  const shader = useMemo(
    () => ({
      uniforms: {
        tDiffuse: { value: null },
        uDisplacement: { value: null },
        uTime: { value: null }
      },
      vertexShader,
      fragmentShader
    }),
    []
  );

  const shaderRef = useRef(null);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => () => effect.dispose(), [effect]);

  useFrame(({ gl, clock }) => {
    effect.update(gl, shaderRef.current.uniforms.uDisplacement);
    const elapsedTime = clock.getElapsedTime();
    shaderRef.current.uniforms.uTime.value = elapsedTime / 2;
  });

  useEffect(() => {
    if (!effect) {
      return;
    }

    effect.setDimensions(dimensions);
  }, [dimensions, effect]);

  useEffect(() => {
    if (!effect) {
      return;
    }

    effect.setMouse(pos);
  }, [effect, pos]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <shaderPass ref={shaderRef} attach="passes-1" args={[shader]} enabled />
  );
};

export default RippleShader;
