import { buttonTokens } from './buttonTokens';
import { atfTokens } from './atfTokens';
import { footerTokens } from './footerTokens';
import { headerTokens } from './headerTokens';
import { ITokenGroup } from '..';

export const componentTokens: ITokenGroup[] = [
  {
    tokens: atfTokens,
    name: 'atf'
  },
  {
    tokens: buttonTokens,
    name: 'button'
  },
  {
    tokens: footerTokens,
    name: 'footer'
  },
  {
    tokens: headerTokens,
    name: 'header'
  }
];
