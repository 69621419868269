import React from 'react';
import { useBreakpoints } from '@lam-agency/toolkit/hooks';
import DesktopATFWidget from './components/Desktop';
import MobileATFWidget from './components/Mobile';
import { type ILink } from 'lam-toolkit-figma-plugin';

export interface IWidgetProps {
  assetLinks?: ILink[];
  quickLinks?: ILink[];
  contactURL: string;
}

const ATFWidget = ({ assetLinks, quickLinks, contactURL }: IWidgetProps) => {
  const { smallDesktop } = useBreakpoints();

  if (!assetLinks?.length && !quickLinks?.length && !contactURL) return null;

  return (
    <>
      {smallDesktop ? (
        <DesktopATFWidget
          contactURL={contactURL}
          assetLinks={assetLinks}
          quickLinks={quickLinks}
        />
      ) : (
        <MobileATFWidget
          contactURL={contactURL}
          assetLinks={assetLinks}
          quickLinks={quickLinks}
        />
      )}
    </>
  );
};

export default ATFWidget;
