import React from 'react';
import { Image } from '@react-three/drei';

/** ============================================================================
 * @component
 */
const ImagePlane = ({ width, height, imageUrl }) => {
  return <Image scale={[width, height]} url={imageUrl} />;
};

export default ImagePlane;
