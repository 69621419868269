// extracted by mini-css-extract-plugin
export var background = "styles-module--background--52452";
export var borderTop = "styles-module--borderTop--4ba4a";
export var card = "styles-module--card--37aae";
export var cardBody = "styles-module--cardBody--ebffc";
export var cardBodySvg = "styles-module--cardBodySvg--33246";
export var cardWrapper = "styles-module--cardWrapper--fbd4b";
export var cards = "styles-module--cards--940ae";
export var container = "styles-module--container--58603";
export var desktop = "1340px";
export var giant = "2200px";
export var heading = "styles-module--heading--7e225";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";