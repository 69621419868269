import React, { useEffect, useRef, useState } from 'react';
import { useMVT } from '@lam-agency/toolkit/stores';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { parseImagesFromFigma } from '@lam-agency/toolkit/utils/figma';
import {
  ATFWidget,
  LayoutProvider,
  TAnimation,
  TextAnimation
} from '@lam-agency/toolkit/components';
import {
  useBreakpoints,
  useScroll,
  useWindowDimensions
} from '@lam-agency/toolkit/hooks';
import { getCSSVariableValue } from '@lam-agency/toolkit/utils';
import { IWidgetProps } from '@lam-agency/toolkit/components';

interface IFields extends IWidgetProps {
  body: string;
  images: string[];
}
interface IProps {
  data: {
    fields: IFields;
  };
}

const FigmaCopyOnImage = ({
  data: {
    fields: { assetLinks, body, images, contactURL, quickLinks }
  }
}: IProps) => {
  const [contentHeight, setContentHeight] = useState(9999); // Prevent scroll animations from triggering on slices below before content is loaded

  const contentRef = useRef<HTMLDivElement>(null);
  const { projectString } = useMVT();
  const { scrollY } = useScroll();
  const { height } = useWindowDimensions();
  const { smallDesktop } = useBreakpoints();

  const contentOverflowHeight = Math.max(0, contentHeight - height);
  const contentTransformY = smallDesktop
    ? -Math.min(contentOverflowHeight, scrollY)
    : 0;

  const allImages = parseImagesFromFigma(projectString, images);

  const amountToScrollBeforeBlurPx = 300; // sync with amoutToScrollBeforeFill in Header.tsx
  const blurMultiplier = 0.02;
  const normalisedScroll = Math.max(0, scrollY - amountToScrollBeforeBlurPx);
  const blurAmountPx = normalisedScroll * blurMultiplier;

  const darkenMultiplier = 0.002;
  const maxDarkenOpacity = 0.75;
  const darkenOpacity = Math.min(
    maxDarkenOpacity,
    normalisedScroll * darkenMultiplier
  );

  const imageScaleMultiplier = 0.0002;
  const maxScale = 1.1;
  const minScale = 1;

  const imageScale = Math.max(
    minScale,
    maxScale - normalisedScroll * imageScaleMultiplier
  );

  const textAnimation = getCSSVariableValue(
    '--atf-text-animation'
  ) as TAnimation;

  useEffect(() => {
    const calculateContentHeight = () => {
      if (!contentRef.current) return;

      const containerHeight = contentRef.current.clientHeight;
      setContentHeight(containerHeight);
    };

    setTimeout(calculateContentHeight, 100);

    window.addEventListener('resize', calculateContentHeight);

    return () => {
      window.removeEventListener('resize', calculateContentHeight);
    };
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.content}
          ref={contentRef}
          style={{ transform: `translateY(${contentTransformY}px)` }}
        >
          {/* todo: how would we know which image to use? */}
          {allImages?.[0]?.image && (
            <img
              aria-hidden
              className={styles.background}
              src={allImages[0].image}
              alt=""
              style={{ transform: `scale(${imageScale})` }}
            />
          )}

          <LayoutProvider grid paddingX paddingY className={styles.layout}>
            <div className={styles.textWrapper}>
              <TextAnimation
                className={cn('d1')}
                text={body}
                animation={textAnimation}
                speed={0.03}
              />
            </div>

            <ATFWidget
              contactURL={contactURL}
              assetLinks={assetLinks}
              quickLinks={quickLinks}
            />
          </LayoutProvider>

          <div
            aria-hidden
            style={{
              backdropFilter: `blur(${blurAmountPx}px)`,
              backgroundColor: `rgba(0, 0, 0, ${darkenOpacity})`
            }}
            className={styles.blur}
          />
        </div>
      </div>
      <div
        aria-hidden
        style={{ height: contentHeight }}
        className={styles.scrollPadding}
      />
    </>
  );
};

export default FigmaCopyOnImage;
