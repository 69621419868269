import React from 'react';
import cn from 'classnames';
import { Colour, IntersectionAnimation } from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { useMVT } from '@lam-agency/toolkit/stores';

const ColourInspector = () => {
  const { colors } = useMVT();
  const filteredColors = colors.filter(
    (color) => color.category === 'primary' || color.category === 'secondary'
  );

  return (
    <div className={styles.container}>
      {filteredColors.map((data, index) => {
        const { id } = data || {};
        const isTwoColumns = filteredColors.length % 2 !== 0 && index === 0;

        return (
          <IntersectionAnimation
            key={`colour-inspector-colour-${id}-${index}`}
            className={cn(styles.card, isTwoColumns && styles.twoColumns)}
            animation="fadeGrow"
          >
            <Colour key={id} className={styles.color} data={data} />
          </IntersectionAnimation>
        );
      })}
    </div>
  );
};

export default ColourInspector;
