import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';

export interface ISlidePillButtonOption {
  label: string;
  value: string;
  active: boolean;
}

interface IProps {
  options: ISlidePillButtonOption[];
  setOptions: React.Dispatch<React.SetStateAction<ISlidePillButtonOption[]>>;
  className?: string;
}

interface IPillBackgroundPosition {
  top?: number;
  left?: number;
  width?: number;
  height?: number;
}

const SlidePillButtons = ({ options, setOptions, className }: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const activeOption = options.find((option) => option.active);

  const getPillBackgroundPosition: () => IPillBackgroundPosition = () => {
    if (!containerRef.current) return {};

    const activeButton = containerRef.current.querySelector(
      `[data-value="${activeOption?.value}"]`
    ) as HTMLButtonElement;

    if (!activeButton) return {};

    const left = activeButton.offsetLeft;
    const top = activeButton.offsetTop;
    const width = activeButton.offsetWidth;
    const height = activeButton.offsetHeight;

    return {
      height,
      left,
      top,
      width
    };
  };

  const [pillBackgroundPosition, setPillBackgroundPosition] =
    useState<IPillBackgroundPosition>(getPillBackgroundPosition());

  const updatePillBackgroundPosition = () => {
    setPillBackgroundPosition(getPillBackgroundPosition());
  };

  useEffect(() => {
    updatePillBackgroundPosition();
  }, [activeOption]);

  useEffect(() => {
    setTimeout(updatePillBackgroundPosition, 100);
  }, []);

  const handleUpdateActiveOption = (option: ISlidePillButtonOption) => {
    setOptions((prev) =>
      prev.map((prevOption) => ({
        ...prevOption,
        active: prevOption.value === option.value
      }))
    );
  };

  return (
    <div ref={containerRef} className={cn(styles.container, className)}>
      <div className={styles.pillBackground} style={pillBackgroundPosition} />
      {options.map((option, i) => (
        <button
          key={i}
          data-value={option.value}
          type="button"
          className={cn(styles.button, {
            [styles.active]: activeOption?.value === option.value
          })}
          onClick={() => handleUpdateActiveOption(option)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default SlidePillButtons;
