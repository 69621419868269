import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import {
  LayoutProvider,
  PageCategoryGroup
} from '@lam-agency/toolkit/components';
import {
  useApp,
  usePageColorScheme,
  usePageNavigation,
  useScroll,
  useWindowDimensions
} from '@lam-agency/toolkit/hooks';
import * as styles from './styles.module.scss';
import { Link } from 'gatsby';
import { TCategory } from '@lam-agency/toolkit/hooks/usePageNavigation';
import { SVG } from '@lam-agency/toolkit/components';

const Header = () => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  const { isHeaderOpen, setIsHeaderOpen } = useApp();
  const { pageCategories } = usePageNavigation();
  const pageColorScheme = usePageColorScheme();
  const { isScrollingDown, scrollY } = useScroll();
  const { height } = useWindowDimensions();

  const scrollThresholdPx = height;

  const amountToScrollBeforeFillPx = 300; // sync with amountToScrollBeforeBlurPx in FigmaCopyOnImage.tsx
  const showBackgroundColor = scrollY >= amountToScrollBeforeFillPx;

  useEffect(() => {
    const isScrollingUp = !isScrollingDown;

    setIsHeaderVisible(() => {
      if (isScrollingUp) {
        return true;
      }

      if (scrollY > scrollThresholdPx) {
        return false;
      }

      return true;
    });
  }, [scrollY]);

  return (
    <>
      {/* <div
        onClick={() => setIsHeaderOpen(false)}
        aria-hidden
        className={cn(styles.bgOverlay, {
          [styles.visible]: isHeaderOpen
        })}
      /> */}

      <header
        className={cn(styles.header, {
          [styles.open]: isHeaderOpen,
          [styles.lightColorScheme]: pageColorScheme === `light`,
          [styles.backgroundColor]: showBackgroundColor,
          [styles.hidden]: !isHeaderVisible
        })}
      >
        <LayoutProvider paddingX className={styles.layout}>
          <Link aria-label="Home" to="/" className={styles.homeLink}>
            <SVG svg="logo" className={styles.logo} />
          </Link>
          {/* <button
            onClick={() => setIsHeaderOpen((prev) => !prev)}
            type="button"
            aria-label="Toggle navigation"
            className={cn(styles.hamburger, { [styles.open]: isHeaderOpen })}
          >
            <div className={styles.line} />
            <div className={styles.line} />
            <div className={styles.line} />
          </button> */}
        </LayoutProvider>
      </header>

      {/* <div
        className={cn(styles.navContainer, {
          [styles.open]: isHeaderOpen,
          [styles.lightColorScheme]: pageColorScheme === `light`
        })}
      >
        <nav className={styles.nav}>
          <div className={styles.navItems}>
            {pageCategories?.map((category: TCategory) => (
              <div key={category.id} className={styles.categoryGroup}>
                <PageCategoryGroup
                  pages={category.pages}
                  title={category.title}
                />
              </div>
            ))}
          </div>
        </nav>
      </div> */}
    </>
  );
};

export default Header;
