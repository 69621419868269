import React from 'react';
import { useSiteMetadata } from '@lam-agency/toolkit/hooks';

interface IProps {
  pageTitle: string;
  description: string;
}

const SEO = ({ pageTitle, description }: IProps) => {
  const meta = useSiteMetadata();
  const siteTitle = meta.title;
  const siteUrl = meta.siteUrl;
  const titleText =
    pageTitle === 'Home' ? siteTitle : `${pageTitle} | ${siteTitle}`;

  return (
    <>
      <title>{titleText}</title>
      <link rel="icon" type="image/png" href="/images/favicon.png" />
      <meta name="description" content={description} />
      <link rel="canonical" href={siteUrl} />

      {/* facebook */}
      <meta property="og:site_name" content={pageTitle} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="/images/open-graph.jpg" />
      <meta property="og:image:alt" content={pageTitle} />

      {/* twitter */}

      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content="/images/open-graph.jpg" />
      <meta name="twitter:image:alt" content={pageTitle} />
    </>
  );
};

export default SEO;
