import { useEffect, useState } from "react";

const useCursor = () => {
  const [cursorPosition, setCursorPosition] = useState({
    x: 0,
    y: 0,
    normalizedX: 0,
  });

  const handleMouseMove = (e) => {
    const x = e.clientX;
    const y = e.clientY;

    const halfWindowWidth = window.innerWidth / 2;

    setCursorPosition({
      x,
      y,
      normalizedX: (x - halfWindowWidth) / halfWindowWidth,
      // normalizedY: y / window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return cursorPosition;
};

export default useCursor;
