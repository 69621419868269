import React from 'react';
import {
  ImageWithCaption,
  IntersectionAnimation,
  IImageWithCaption
} from '@lam-agency/toolkit/components';
import cn from 'classnames';

import * as styles from './styles.module.scss';

interface IProps {
  items: IImageWithCaption[];
  columns?: 3 | 4;
  borderRadius?: boolean;
  className?: string;
}

const ImageGrid = ({ items, columns = 3, borderRadius, className }: IProps) => {
  return (
    <div className={cn(styles.container, className)}>
      {items.map((item, index) => {
        const delay = index * 50;
        return (
          <IntersectionAnimation
            key={`image-grid-item-${item?.caption}-${index}`}
            className={styles.gridItemWrapper}
            animation="fadeGrow"
            delay={delay}
          >
            <ImageWithCaption className={styles.gridItem} data={item} />
          </IntersectionAnimation>
        );
      })}
    </div>
  );
};

export default ImageGrid;
