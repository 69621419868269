import { ITokenGroup } from '..';
import { borderRadiusTokens } from './borderRadius';
import { styleInspectorTokens } from './styleInspector';

export const sliceTokens: ITokenGroup[] = [
  {
    tokens: borderRadiusTokens,
    name: 'border-radius'
  },
  {
    tokens: styleInspectorTokens,
    name: 'style-inspector'
  }
];
