import React, { useState } from 'react';
import cn from 'classnames';
import {
  ISlidePillButtonOption,
  SlidePillButtons
} from '@lam-agency/toolkit/components';

import gridDesktop from '@lam-agency/toolkit/assets/images/slices/FigmaGrid/grid-desktop-dark.png';
import gridTablet from '@lam-agency/toolkit/assets/images/slices/FigmaGrid/grid-tablet-dark.png';
import gridMobile from '@lam-agency/toolkit/assets/images/slices/FigmaGrid/grid-mobile-dark.png';

import * as styles from './styles.module.scss';

interface ITabData {
  key: string;
  caption: string;
  image: any;
}

const GridVisualiser = () => {
  const tabs: ITabData[] = [
    {
      key: 'desktop',
      caption:
        '12 columns, 24px fixed margin, 24px gutter. Columns stretch to fit',
      image: gridDesktop
    },
    {
      key: 'tablet',
      caption:
        '8 columns, 24px fixed margin, 16px gutter. Columns stretch to fit',
      image: gridTablet
    },
    {
      key: 'mobile',
      caption: '8 columns, 8px gutter. Columns stretch to fit',
      image: gridMobile
    }
  ];

  const [options, setOptions] = useState<ISlidePillButtonOption[]>([
    {
      active: true,
      label: 'Desktop',
      value: 'desktop'
    },
    {
      active: false,
      label: 'Tablet',
      value: 'tablet'
    },
    {
      active: false,
      label: 'Mobile',
      value: 'mobile'
    }
  ]);

  const activeKey = options.find((option) => option.active)?.value || 'desktop';
  const activeData = tabs.find((tab) => tab.key === activeKey) as ITabData;

  return (
    <div className={styles.container}>
      <SlidePillButtons
        className={cn('caption', styles.controls)}
        options={options}
        setOptions={setOptions}
      />

      <div className={styles.imageContainer}>
        <img className={styles.image} src={activeData.image} alt="" />
      </div>

      <p className={cn('caption', styles.caption)}>{activeData.caption}</p>
    </div>
  );
};

export default GridVisualiser;
