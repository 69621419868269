// extracted by mini-css-extract-plugin
export var button = "styles-module--button--f63b4";
export var buttonContainer = "styles-module--buttonContainer--7b092";
export var container = "styles-module--container--7e078";
export var desktop = "1340px";
export var disabled = "styles-module--disabled--05867";
export var error = "styles-module--error--3d03a";
export var giant = "2200px";
export var hasArrow = "styles-module--hasArrow--63f54";
export var hasSearch = "styles-module--hasSearch--5b192";
export var hint = "styles-module--hint--7d997";
export var icon = "styles-module--icon--73e3f";
export var iconContainer = "styles-module--iconContainer--bf2f3";
export var input = "styles-module--input--3f5b1";
export var inputContainer = "styles-module--inputContainer--19c47";
export var label = "styles-module--label--93817";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var searchIcon = "styles-module--searchIcon--eb0b6";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var textarea = "styles-module--textarea--f543f";
export var topText = "styles-module--topText--af241";
export var warning = "styles-module--warning--9366d";