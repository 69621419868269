// extracted by mini-css-extract-plugin
export var caption = "styles-module--caption--7c5fe";
export var container = "styles-module--container--41c76";
export var controls = "styles-module--controls--e1b27";
export var desktop = "1340px";
export var giant = "2200px";
export var image = "styles-module--image--80edf";
export var imageContainer = "styles-module--imageContainer--4b047";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";