import React from 'react';
import * as styles from './styles.module.scss';
import { SVG } from '@lam-agency/toolkit/components';

const LoadingScreen = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logoContainer}>
          <SVG svg="wordmark" className={styles.logo} />

          <div className={styles.circle} />
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
