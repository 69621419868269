import React from 'react';
import {
  Imagery,
  LayoutProvider,
  SliceHeader,
  TextImageGrid
} from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';

interface IProps {
  data: {
    fields: {
      heading?: string;
      body?: string;
    };
  };
}

const FigmaMemeImagery = ({ data: { fields } }: IProps) => {
  const { heading, body } = fields || {};

  // const imageGridItems = parseImagesFromFigma(images);
  // const buttons = parseButtonDataFromFigma([buttonText1, buttonText2]);
  const imageGridItems = [
    {
      image: '/mvt-images/art-direction/meme-imagery-2.jpg'
    },
    {
      image: '/mvt-images/art-direction/meme-imagery-3.jpg'
    }
  ];

  return (
    <LayoutProvider paddingX paddingY>
      <SliceHeader data={{ heading }} />

      <Imagery
        data={{
          image: '/mvt-images/art-direction/meme-imagery-1.jpg',
          button: { text: 'Download PNG', icon: 'download' }
        }}
      />

      <TextImageGrid body={body} bodyPosition="right" items={imageGridItems} />
    </LayoutProvider>
  );
};

export default FigmaMemeImagery;
