import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
  style?: React.CSSProperties;
}

const ResizeTextArea = ({ setText, text, className, style }: IProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const calculateHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset height to calculate new scroll height
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + 'px';
    }
  };

  useEffect(() => {
    calculateHeight();
  }, [text]); // Adjust height when text changes

  useEffect(() => {
    setTimeout(calculateHeight, 100);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', calculateHeight);

    return () => {
      window.removeEventListener('resize', calculateHeight);
    };
  }, []);

  return (
    <div className={cn(className)}>
      <textarea
        ref={textareaRef}
        value={text}
        aria-label="Preview text"
        onChange={handleInput}
        className={styles.textarea}
        rows={1}
        style={style}
        spellCheck={false}
      />
    </div>
  );
};

export default ResizeTextArea;
