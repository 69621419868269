import React from 'react';
import cn from 'classnames';
import { SVG, TSVG } from '@lam-agency/toolkit/components';
import {
  IntersectionAnimation,
  LayoutProvider,
  Link
} from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';
import { getWhiteIfBackgroundIsTransparent } from '@lam-agency/toolkit/utils';

export interface IResourceCard {
  meta: string;
  link: {
    text: string;
    url: string;
  };
  svg?: TSVG;
}

interface IProps {
  data: {
    heading?: string;
    cards: IResourceCard[];
  };
  borderTop?: boolean;
  delayOffset?: number;
}

const ResourceCards = ({
  data,
  borderTop = false,
  delayOffset = 0
}: IProps) => {
  const { heading, cards } = data || {};

  if (!cards?.[0]) return null;

  return (
    <LayoutProvider
      className={cn(styles.container, { [styles.borderTop]: borderTop })}
      grid
    >
      <h4 className={cn('b1', styles.heading)}>{heading}</h4>

      <div className={styles.cards}>
        {cards.map((card: IResourceCard, index) => {
          const {
            meta,
            link: { text, url },
            svg
          } = card || {};

          const delay = (delayOffset + index) * 50;

          return (
            <IntersectionAnimation
              key={`resource-cards-card-${text}-${index}`}
              delay={delay}
              animation="fadeGrow"
            >
              <div
                style={{ background: getWhiteIfBackgroundIsTransparent() }}
                className={styles.cardWrapper}
              >
                <div aria-hidden className={styles.background} />

                <Link className={styles.card} url={url}>
                  <p className="caption">{meta}</p>

                  <div className={cn('b1', styles.cardBody)}>
                    <p>{text}</p>

                    {svg && (
                      <figure className={styles.cardBodySvg}>
                        <SVG svg={svg} />
                      </figure>
                    )}
                  </div>
                </Link>
              </div>
            </IntersectionAnimation>
          );
        })}
      </div>
    </LayoutProvider>
  );
};

export default ResourceCards;
