import React from 'react';
import {
  LayoutProvider,
  ResourceCards,
  SliceHeader
} from '@lam-agency/toolkit/components';
import { IResourceCard } from '@lam-agency/toolkit/components/Figma/ResourceCards';

interface IProps {
  data: {
    fields: {
      heading: string;
      //
      'group/1/heading': string;
      'group/1/card/1/meta': string;
      'group/1/card/1/buttonText': string;
      'group/1/card/2/meta': string;
      'group/1/card/2/buttonText': string;
      'group/1/card/3/meta': string;
      'group/1/card/3/buttonText': string;
      'group/1/card/4/meta': string;
      'group/1/card/4/buttonText': string;
      'group/1/card/5/meta': string;
      'group/1/card/5/buttonText': string;
      'group/1/card/6/meta': string;
      'group/1/card/6/buttonText': string;
      //
      'group/2/heading': string;
      'group/2/card/1/meta': string;
      'group/2/card/1/buttonText': string;
      'group/2/card/2/meta': string;
      'group/2/card/2/buttonText': string;
      'group/2/card/3/meta': string;
      'group/2/card/3/buttonText': string;
      'group/2/card/4/meta': string;
      'group/2/card/4/buttonText': string;
      'group/2/card/5/meta': string;
      'group/2/card/5/buttonText': string;
      'group/2/card/6/meta': string;
      'group/2/card/6/buttonText': string;
    };
  };
}

type ICardGroup = {
  heading: string;
  cards: IResourceCard[];
};

type ICardGroups = {
  group1: ICardGroup;
  group2: ICardGroup;
};

const FigmaLinksAndResources = ({ data: { fields } }: IProps) => {
  const groupButtons = (data: Record<string, string>): ICardGroups => {
    const groupData: ICardGroups = {
      group1: {
        heading: fields?.['group/1/heading'] || 'Group 1',
        cards: []
      },
      group2: {
        heading: fields?.['group/2/heading'] || 'Group 2',
        cards: []
      }
    };

    // lol?
    const fieldsData = fields as unknown as Record<string, string>;

    for (const [key, value] of Object.entries(data)) {
      if (
        !key?.includes('group/') ||
        !key?.includes('card/') ||
        !value?.includes('>')
      ) {
        continue;
      }

      const parentIndex = parseInt(key.split('group/')?.[1]?.charAt(0));
      const cardIndex = parseInt(key.split('card/')?.[1]?.charAt(0));
      const prefix = `group/${parentIndex}/card/${cardIndex}`;

      const [text, linkUrl] = value.split('>');

      const button: IResourceCard = {
        meta: fieldsData?.[`${prefix}/meta`] || 'Link',
        link: { text, url: linkUrl },
        svg: linkUrl?.includes('dropbox.com') ? 'download' : 'externalLink'
      };

      if (parentIndex === 1) {
        groupData.group1.cards[cardIndex - 1] = button;
      } else if (parentIndex === 2) {
        groupData.group2.cards[cardIndex - 1] = button;
      }
    }

    return groupData;
  };

  const groupData = groupButtons(fields as unknown as Record<string, string>);

  return (
    <LayoutProvider paddingX paddingY>
      <SliceHeader data={{ heading: fields?.heading || '', buttons: [] }} />

      {Object.keys(groupData).map((groupKey, index) => (
        <ResourceCards
          key={`resource-cards-group-${index}`}
          data={groupData[groupKey as keyof ICardGroups]}
        />
      ))}
    </LayoutProvider>
  );
};

export default FigmaLinksAndResources;
