import React from 'react';
import cn from 'classnames';
import { SVG } from '@lam-agency/toolkit/components';
import { Tag } from '@lam-agency/toolkit/components';
import { getTextThemeByBackgroundHex } from '@lam-agency/toolkit/utils';

import * as styles from './styles.module.scss';
import { useApp } from '@lam-agency/toolkit/hooks';

interface IProps {
  data: {
    hex: string;
    name: string;
    category: string;
  };
  className?: string;
}
const Colour = ({ data, className }: IProps) => {
  const { updateNotification } = useApp();
  const { hex, name, category } = data || {};
  const theme = getTextThemeByBackgroundHex(hex);

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    updateNotification('Copied to clipboard');
  };

  return (
    <article
      className={cn(styles.card, styles[theme], className)}
      style={{ background: `${hex}` }}
    >
      <header className={styles.cardHeader}>
        <button
          className={styles.textButton}
          onClick={() => copyToClipboard(name)}
        >
          <p className={cn('b1', styles.text)}>
            <span>{name}</span>
            <SVG svg="copy" />
          </p>
        </button>

        <Tag text={category} theme={theme} />
      </header>

      <div className={cn('caption', styles.cardBody)}>
        <button
          className={styles.textButton}
          onClick={() => copyToClipboard(hex)}
        >
          <p className={styles.text}>
            <span>{hex}</span>
            <SVG svg="copy" />
          </p>
        </button>

        {/* <button
                className={styles.textButton}
                onClick={() => copyToClipboard(rgba)}
              >
                <p className={styles.text}>
                  <span>{rgba}</span>
                  <SVG svg="copy" />
                </p>
              </button> */}

        {/* <button
                className={styles.textButton}
                onClick={() => copyToClipboard(pms)}
              >
                <p className={styles.text}>
                  <span>{pms}</span>
                  <SVG svg="copy" />
                </p>
              </button> */}
      </div>
    </article>
  );
};

export default Colour;
